import React, { Component, Suspense, useRef } from 'react'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import { withTranslation } from 'react-i18next';

import "./CompanyAddress.css"

import { MdOutlineEmail } from "react-icons/md";
import { VscHome } from "react-icons/vsc";
import { FiPhoneCall } from "react-icons/fi";

import ReCAPTCHA from "react-google-recaptcha";

import axios from 'axios';

class CompanyAddress extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      token: "",
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: ""
    };
    this.recaptchaRef = React.createRef();
  }

  onSubmit = (event) => {
    if (this.state.token != "") {
      console.log(this.state.name);
    }
  }

  handleChange = value => {
    this.setState({ token: value });
  };
  render() {
    const { t } = this.props
    return (
      <Suspense fallback={null}>
        <Header />
        <main id="main">

          <section id="company-address" className="company-address pt-5">,
            <div className="company-address-cover-container">
              <div className="map-cover">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.407598207716!2d100.5393647148298!3d13.693745990385098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29fe6949aa721%3A0x53ec37012403c757!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4lOC4o-C4reC4m-C4o-C4tOC4iuC4geC4o-C4uOC5iuC4myDguKrguLPguJnguLHguIHguIfguLLguJnguYPguKvguI3guYggKERyb3ByaWNoR3JvdXAp!5e0!3m2!1sth!2sth!4v1674706360507!5m2!1sth!2sth" 
                width="600" height="450" loading="lazy" ></iframe>
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.4599814156577!2d100.54169287210878!3d13.693891924496365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29f58d185c6ef%3A0x9b86894738120abb!2zMTA2IOC4luC4meC4meC4meC4meC4l-C4o-C4tSDguYHguILguKfguIcg4LiK4LmI4Lit4LiH4LiZ4LiZ4LiX4Lij4Li1IOC5gOC4guC4lSDguKLguLLguJnguJnguLLguKfguLIg4LiB4Lij4Li44LiH4LmA4LiX4Lie4Lih4Lir4Liy4LiZ4LiE4LijIDEwMTIw!5e0!3m2!1sth!2sth!4v1647838717869!5m2!1sth!2sth"
                  loading="lazy"></iframe> */}
              </div>
              <div className="company-address-content">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6 company-address-form">
                      <div role="form" className="company-address-form" >
                        <div className="row">
                          <div className="form-group col-md-6">
                            <input type="text" name="name" className="form-control input-placeholder" id="name" placeholder="Your Name *" required style={styles.defaultTxtEN} onChange={e => this.setState({ name: e.target.value })} value={this.state.name} />
                          </div>
                          <div className="form-group col-md-6">
                            <input type="email" className="form-control input-placeholder" name="email" id="email" placeholder="Your Email *" required style={styles.defaultTxtEN} onChange={e => this.setState({ email: e.target.value })} value={this.state.email} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <input type="text" className="form-control input-placeholder" name="phone" id="phone" placeholder="Your Phone *" required style={styles.defaultTxtEN} onChange={e => this.setState({ phone: e.target.value })} value={this.state.phone} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <input type="text" className="form-control input-placeholder" name="subject" id="subject" placeholder="Subject of message *" required style={styles.defaultTxtEN} onChange={e => this.setState({ subject: e.target.value })} value={this.state.subject} />
                          </div>
                        </div>
                        <div className="form-group">
                          <textarea className="form-control input-placeholder" name="message" rows="6" required placeholder="Type your message" style={styles.defaultTxtEN} onChange={e => this.setState({ message: e.target.value })} value={this.state.message}>{this.state.message}</textarea>
                        </div>
                        <ReCAPTCHA
                          sitekey="6LdzHPoeAAAAANUcNllIUE8ci6c4MlqJwCqWdSZr"
                          onChange={this.handleChange}
                        />
                        <div className="mt-2">
                          <button className="btn btn-company-address-submit" style={styles.defaultTxtEN} onClick={this.onSubmit}>Submit</button>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 company-address-info">
                      <div className="info-head">
                        <h1>{t('menu7.label')}</h1>
                        <p>{t('footer_lb_company_address.label')}</p>
                      </div>
                      {/* <div className="email company-address-info-desc col-sm-6">
                        <i className="bi bi-envelope"><MdOutlineEmail /></i>
                        <p style={styles.defaultTxtEN}>info@example.com</p>
                      </div> */}
                      <div className="address company-address-info-desc col-sm-8">
                        <i className="bi bi-geo-alt"><VscHome /></i>
                        <p>{t('contact_address.label')}</p>
                      </div>
                      <div className="phone company-address-info-desc col-sm-8">
                        <i className="bi bi-phone"><FiPhoneCall /></i>
                        <p>{t('contact_number.label')} <br />
                          {t('contact_human3.label')}<br />
                          {t('contact_human2.label')}<br />
                          {t('contact_human.label')}<br />
                          {t('contact_human4.label')}<br />
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </Suspense>
    )
  }
}
export default withTranslation()(CompanyAddress)
const styles = {
  defaultTxtEN: {
    fontFamily: "Noto Sans"
  },

}
