import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';

const DownloadModal = ({ divHandleClass,btnHandleClass, txt_lb_1,txt_lb_2}) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className={divHandleClass}>
                <button type="button" className={btnHandleClass} onClick={handleShow}>{txt_lb_1}</button>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{txt_lb_2}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-sm-6 container align-items-center justify-content-center">
                        <div className='col-sm-4 pt-2 pb-2 text-center'>
                            <a href={process.env.REACT_APP_GPLAY} target="_blank" rel="external"><img src="./assets/img/StoreIcon/Play_Store_black.png" alt="" /></a>
                        </div >
                        <div className='col-sm-4 pt-2 pb-2 text-center'>
                            <a href={process.env.REACT_APP_ASTORE} target="_blank" rel="external"><img src="./assets/img/StoreIcon/App_Store_black.png" alt="" /></a>
                        </div>
                        <div className='col-sm-4 pt-2 pb-2 text-center'>
                            <a href={process.env.REACT_APP_AGALLERY} target="_blank" rel="external"><img src="./assets/img/StoreIcon/Appgallery_black.png" alt="" /></a>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>ปิด</Button>
                    {/* <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default DownloadModal;
