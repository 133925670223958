import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React, { useEffect, Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";

import "./i18n";
import reportWebVitals from "./reportWebVitals";

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { browserHistory } from 'react-router'

import App from "./App";
import ServicePolicy from "./Partial/ServicePolicy";
import PrivacyPolicy from "./Partial/PrivacyPolicy";
import TermsAndConditions from "./Partial/TermsAndConditions";
import PurchasePolicy from "./Partial/PurchasePolicy";
import CompanyAddress from "./Partial/CompanyAddress";

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={null}>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/service-policy" element={<ServicePolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/purchase-policy" element={<PurchasePolicy />} />
        <Route path="/company-address" element={<CompanyAddress />} />
      </Routes>
    </Suspense>
  </BrowserRouter>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
