import React, { Component } from 'react'
import "./CSS/downloadContent.css"
export default class downloadContent extends Component {
  render() {
    return (
      <section id="download" className="download pt-3 pb-5 d-flex pseudo_border">
        <div className="container">
          <div className="row text-center align-items-center justify-content-center">

            <div className="col-md-4 text-center  mb-4">
              <div className="text-center ">
                <label className='txtRating' style={styles.defaultTxtEN}>Rating 4.8</label>
              </div>
              <div className="starbox">
                <img alt="image" className="img-fluid" src="./assets/img/VectorStart.png" />
                <img alt="image" className="img-fluid" src="./assets/img/VectorStart.png" />
                <img alt="image" className="img-fluid" src="./assets/img/VectorStart.png" />
                <img alt="image" className="img-fluid" src="./assets/img/VectorStart.png" />
                <img alt="image" className="img-fluid" src="./assets/img/VectorStart.png" />
              </div>

            </div>

            <div className="col-12 col-md-12" style={{ width: "fit-content" }}>
              <div className="row store-icon">

                <div className='col-sm-4 '>
                  <a href={process.env.REACT_APP_GPLAY} target="_blank" rel="external"><img src="./assets/img/StoreIcon/Play_Store_black.png" alt="" /></a>
                </div>

                <div className='col-sm-4'>
                  <a href={process.env.REACT_APP_ASTORE} target="_blank" rel="external"><img src="./assets/img/StoreIcon/App_Store_black.png" alt="" /></a>
                </div>

                <div className='col-sm-4'>
                  <a href={process.env.REACT_APP_AGALLERY} target="_blank" rel="external"><img src="./assets/img/StoreIcon/Appgallery_black.png" alt="" /></a>
                </div>

              </div>
            </div>

          </div>
        </div>
      </section>
    )
  }
}
const styles = {
  defaultTxtEN: {
    fontFamily: "Noto Sans"
  }
}