import React, { Component, Suspense, useState, useEffect } from 'react'

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import { withTranslation } from 'react-i18next';
import Markdown from '../Components/Markdown';
import privacynoticeTH from '../modules/privacy-notice-th.md';
import privacynoticeEN from '../modules/privacy-notice-en.md';

import { useTranslation } from "react-i18next";

// class PrivacyPolicy extends Component {
// class PrivacyPolicy extends Component {
const PrivacyPolicy = () => {
  // render() {
  //   const { t } = this.props
  const { t, i18n } = useTranslation();
  const [postMarkdownPrivacynoticeTH, setPostMarkdownPrivacynoticeTH] = useState('');
  const [postMarkdownPrivacynoticeEN, setPostMarkdownPrivacynoticeEN] = useState('');

  useEffect(() => {
    fetch(privacynoticeTH)
      .then((response) => response.text())
      .then((text) => {
        // Logs a string of Markdown content.
        // Now you could use e.g. <rexxars/react-markdown> to render it.
        // console.log(text);
        setPostMarkdownPrivacynoticeTH(text);
      });
    fetch(privacynoticeEN)
      .then((response) => response.text())
      .then((text) => {
        // Logs a string of Markdown content.
        // Now you could use e.g. <rexxars/react-markdown> to render it.
        // console.log(text);
        setPostMarkdownPrivacynoticeEN(text);
      });
  }, [])
  return (
    <Suspense fallback={null}>
      <Header />
      <main id="main">
        <section id="privacy-policy" className="pt-5 pb-5">
          <div className="container" >
            {/* <h1 className='text-center'>{t('privacy_policy_h1.label')}</h1>
              <h5 className='text-center'>{t('privacy_policy_h2.label')}</h5>
              <p>{t('privacy_policy_p1.label')}</p>
              <p>{t('privacy_policy_p2.label')}</p>
              <p>{t('privacy_policy_p3.label')}</p>
              <h5>{t('privacy_policy_p4.label')}</h5>
              <p>{t('privacy_policy_p5.label')}</p>
              <h5>{t('privacy_policy_p6.label')}</h5>
              <ul>
                <li>{t('privacy_policy_p7.label')}</li>
                <li>{t('privacy_policy_p8.label')}</li>
                <li>{t('privacy_policy_p9.label')}</li>
                <li>{t('privacy_policy_p10.label')}</li>
                <li>{t('privacy_policy_p11.label')}</li>
                <li>{t('privacy_policy_p12.label')}</li>
                <li>{t('privacy_policy_p13.label')}</li>
                <li>{t('privacy_policy_p14.label')}</li>
                <li>{t('privacy_policy_p15.label')}</li>
                <li>{t('privacy_policy_p16.label')}</li>
                <li>{t('privacy_policy_p17.label')}</li>
                <li>{t('privacy_policy_p18.label')}</li>
                <li>{t('privacy_policy_p19.label')}</li>
                <li>{t('privacy_policy_p20.label')}</li>
                <li>{t('privacy_policy_p21.label')}</li>
                <li>{t('privacy_policy_p22.label')}</li>
                <li>{t('privacy_policy_p23.label')}</li>
              </ul>
              <h5>{t('privacy_policy_p24.label')}</h5>
              <ul>
                <li>{t('privacy_policy_p25.label')}</li>
                <li>{t('privacy_policy_p26.label')}</li>
                <li>{t('privacy_policy_p27.label')}</li>
                <li>{t('privacy_policy_p28.label')}</li>
                <li>{t('privacy_policy_p29.label')}</li>
                <li>{t('privacy_policy_p30.label')}</li>
                <li>{t('privacy_policy_p31.label')}</li>
                <li>{t('privacy_policy_p32.label')}</li>
                <li>{t('privacy_policy_p33.label')}</li>
                <li>{t('privacy_policy_p34.label')}</li>
              </ul>
              <h5>{t('privacy_policy_p35.label')}</h5>
              <p>{t('privacy_policy_p36.label')}</p>
              <ul>
                <li>{t('privacy_policy_p37.label')}</li>
                <li>{t('privacy_policy_p38.label')}</li>
                <li>{t('privacy_policy_p39.label')}</li>
                <li>{t('privacy_policy_p40.label')}</li>
                <li>{t('privacy_policy_p41.label')}</li>
                <li>{t('privacy_policy_p42.label')}</li>
                <li>{t('privacy_policy_p43.label')}</li>
                <li>{t('privacy_policy_p44.label')}</li>
                <li>{t('privacy_policy_p44.label')}</li>
                <li>{t('privacy_policy_p46.label')}</li>
                <li>{t('privacy_policy_p47.label')}</li>
                <li>{t('privacy_policy_p48.label')}</li>
              </ul>
              <h5>{t('privacy_policy_p49.label')}</h5>
              <p>{t('privacy_policy_p50.label')}</p>
              <ul>
                <li>{t('privacy_policy_p51.label')}</li>
                <li>{t('privacy_policy_p52.label')}</li>
                <li>{t('privacy_policy_p53.label')}</li>
                <li>{t('privacy_policy_p54.label')}</li>
                <li>{t('privacy_policy_p55.label')}</li>
                <li>{t('privacy_policy_p56.label')}</li>
                <li>{t('privacy_policy_p57.label')}</li>
              </ul>
              <h5>{t('privacy_policy_p58.label')}</h5>
              <p>{t('privacy_policy_p59.label')}</p>
              <h5>{t('privacy_policy_p60.label')}</h5>
              <p>{t('privacy_policy_p61.label')}</p>
              <h5>{t('privacy_policy_p62.label')}</h5>
              <p>{t('privacy_policy_p63.label')}</p>
              <p>{t('privacy_policy_p64.label')}</p>
              <h5>{t('privacy_policy_p65.label')}</h5>
              <p>{t('privacy_policy_p92.label')}</p>
              <h5>{t('privacy_policy_p66.label')}</h5>
              <p>{t('privacy_policy_p67.label')}</p>
              <h5>{t('privacy_policy_p68.label')}</h5>
              <p>{t('privacy_policy_p69.label')}</p>
              <h5>{t('privacy_policy_p70.label')}</h5>
              <p>{t('privacy_policy_p71.label')}</p>
              <h5>{t('privacy_policy_p72.label')}</h5>
              <p>{t('privacy_policy_p73.label')}</p>
              <h5>{t('privacy_policy_p74.label')}</h5>
              <p>{t('privacy_policy_p75.label')}</p>
              <h5>{t('privacy_policy_p76.label')} </h5>
              <p>{t('privacy_policy_p77.label')}</p>
              <h5>{t('privacy_policy_p78.label')}</h5>
              <p>{t('privacy_policy_p79.label')}</p>
              <p>{t('privacy_policy_p80.label')}</p>
              <h5>{t('privacy_policy_p81.label')}</h5>
              <p>{t('privacy_policy_p82.label')}</p>
              <h5>{t('privacy_policy_p83.label')}</h5>
              <p>{t('privacy_policy_p84.label')}</p>
              <h5>{t('privacy_policy_p85.label')}</h5>
              <p>{t('privacy_policy_p86.label')}</p>
              <h5>{t('privacy_policy_p93.label')}</h5>
              <p>{t('privacy_policy_p94.label')}</p>
              <ol>
                <li>{t('privacy_policy_p95.label')}</li>
                <li>{t('privacy_policy_p96.label')}</li>
                <li>{t('privacy_policy_p97.label')}</li>
                <li>{t('privacy_policy_p98.label')}</li>
                <li>{t('privacy_policy_p99.label')}</li>
              </ol>
              <p>{t('privacy_policy_p100.label')}</p>
              <h5>{t('privacy_policy_p87.label')}</h5>
              <p>{t('privacy_policy_p88.label')}</p>
              <p>{t('privacy_policy_p89.label')}</p>
              <h5>{t('privacy_policy_p90.label')}</h5>
              <p>{t('privacy_policy_p91.label')}</p> */}
            <Markdown>{(i18n.language == 'th' ? postMarkdownPrivacynoticeTH : postMarkdownPrivacynoticeEN)}</Markdown>
          </div>
        </section>
      </main>
      <Footer />
    </Suspense>
  )
  // }
}
// export default withTranslation()(PrivacyPolicy)
export default PrivacyPolicy