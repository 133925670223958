import React, { Component, Suspense, useState, useEffect } from 'react'

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import { useTranslation } from 'react-i18next';
import Markdown from '../Components/Markdown';

import termsAndConditionsTH from '../modules/terms-and-conditions-th.md';
import termsAndConditionsEN from '../modules/terms-and-conditions-en.md';

// class TermsAndConditions extends Component {
const TermsAndConditions = () => {
  // render() {
  const { t, i18n } = useTranslation();
  const [postMarkdownTermsAndConditionsTH, setPostMarkdownTermsAndConditionsTH] = useState('');
  const [postMarkdownTermsAndConditionsEN, setPostMarkdownTermsAndConditionsEN] = useState('');
  useEffect(() => {
    fetch(termsAndConditionsTH)
      .then((response) => response.text())
      .then((text) => {
        // Logs a string of Markdown content.
        // Now you could use e.g. <rexxars/react-markdown> to render it.
        // console.log(text);
        setPostMarkdownTermsAndConditionsTH(text);
      });
    fetch(termsAndConditionsEN)
      .then((response) => response.text())
      .then((text) => {
        // Logs a string of Markdown content.
        // Now you could use e.g. <rexxars/react-markdown> to render it.
        // console.log(text);
        setPostMarkdownTermsAndConditionsEN(text);
      });
  }, []);
  return (
    <Suspense fallback={null}>
      <Header />
      <main id="main">
        <section id="terms-and-conditions" className="pt-5 pb-5">
          <div className="container" >
            {/* <h1 className='text-center'>{t('terms_and_conditions_h1.label')}</h1>
              <h5 className='text-center'>{t('terms_and_conditions_h2.label')}</h5>
              <p>{t('terms_and_conditions_p1.label')}</p>
              <p>{t('terms_and_conditions_p2.label')}</p>
              <ol>
                <li>{t('terms_and_conditions_p3.label')}</li>
                <li>{t('terms_and_conditions_p4.label')}</li>
                <li>{t('terms_and_conditions_p5.label')}</li>
              </ol>
              <h5>{t('terms_and_conditions_p6.label')}</h5>
              <p>{t('terms_and_conditions_p7.label')}</p>
              <ul>
                <li>{t('terms_and_conditions_p8.label')}</li>
                <li>{t('terms_and_conditions_p9.label')}</li>
                <li>{t('terms_and_conditions_p10.label')}</li>
                <li>{t('terms_and_conditions_p11.label')}</li>
                <li>{t('terms_and_conditions_p12.label')}</li>
              </ul>
              <h5>{t('terms_and_conditions_p13.label')}</h5>
              <ul>
                <li>{t('terms_and_conditions_p14.label')}</li>
                <li>{t('terms_and_conditions_p15.label')}</li>
                <li>{t('terms_and_conditions_p16.label')}</li>
                <li>{t('terms_and_conditions_p17.label')}</li>
              </ul>
              <h5>{t('terms_and_conditions_p18.label')}</h5>
              <ul>
                <li>{t('terms_and_conditions_p19.label')}</li>
                <li>{t('terms_and_conditions_p20.label')}</li>
                <li>{t('terms_and_conditions_p21.label')}</li>
                <li>{t('terms_and_conditions_p22.label')}</li>
              </ul>

              <h5>{t('terms_and_conditions_p23.label')}</h5>
              <p>{t('terms_and_conditions_p24.label')}</p>
              <h5>{t('terms_and_conditions_p25.label')}</h5>
              <p>{t('terms_and_conditions_p26.label')}</p>

              <p>{t('terms_and_conditions_p27.label')}</p> */}
            <Markdown>{(i18n.language == 'th' ? postMarkdownTermsAndConditionsTH : postMarkdownTermsAndConditionsEN)}</Markdown>
          </div>
        </section>
      </main>
      <Footer />
    </Suspense>
  )
  // }
}
// export default withTranslation()(TermsAndConditions)
export default TermsAndConditions