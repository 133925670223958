import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

import "./CSS/bannerPartnerContent.css"
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class bannerPartnerContent extends Component {
  render() {
    const { t } = this.props
    const img_data = [
      { src: "./assets/img/BannerPartner/1.png" },
      { src: "./assets/img/BannerPartner/2.png" },
      { src: "./assets/img/BannerPartner/3.png" },
      { src: "./assets/img/BannerPartner/4.png" },
      { src: "./assets/img/BannerPartner/5.png" },
      { src: "./assets/img/BannerPartner/6.png" },
      { src: "./assets/img/BannerPartner/7.png" },
      { src: "./assets/img/BannerPartner/8.png" }

    ];
    const renderSlides = () =>
      img_data.map((data, index) => (
        <React.Fragment key={index}>
          <div className='col-sm-3 store-icon'><img src={data.src} alt="" /></div>
        </React.Fragment>

      ));
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: -1,
      autoplay: true,
      autoplaySpeed: 3000
    };
    return (
      <section id="banner-partner" className="pt-5 pb-5 d-flex pseudo_border">
        <div className="container">

          <div className="text-center align-items-center justify-content-center ">
            <h1 >{t('banner_bartner_lb1.label')}</h1>

            <div className='default-box'>
              <div className="row store-icon">
                {
                  img_data.map((data, index) => (
                    <React.Fragment key={index}>
                      <div className='col-sm-3'><img src={data.src} alt="" /></div>
                    </React.Fragment>

                  ))
                }
              </div>
            </div>
            <div className='slick-box'>
              <Slider {...settings}>
                {renderSlides()}
              </Slider>
            </div>
          </div>

        </div>
      </section>
    )
  }
}
export default withTranslation()(bannerPartnerContent)

const styles = {


}