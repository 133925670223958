import React, { Component } from 'react'
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";

import Data from "./Data/businessman"
import "./CSS/SuccessfulBusinessmanContent.css"

function Arrow(props) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow ";
    const char = props.type === "next" ? <MdOutlineArrowForwardIos /> : <MdOutlineArrowBackIos />;
    return (
        <span className={className} onClick={props.onClick}>
            {char}
        </span>
    );
}
function appendDots(dots) {
    return (
        <div >
            <ul > {dots} </ul>
        </div>
    );
}
export default class SuccessfulBusinessmanContent extends Component {

    render() {
        var uri = "./assets/img/Businessman/"
        const renderSlides = () =>
            Data.map((data, index) => (
                <React.Fragment key={index}>
                    <div>
                        <div className=' align-items-center text-center justify-content-center' style={styles.defaultTxtTH}>
                            <div className='data-box-img '>
                                <img src={uri + data.img} alt="" />
                            </div>
                            <div className='data-box-desc '>
                                <div className='col-sm-6 mx-auto'>
                                    <label>{data.desc}</label>
                                </div>
                            </div>
                            <div className='mx-auto data-box-name'>
                                <label>{data.name}</label>
                            </div>
                            <div className='mx-auto data-box-income'>
                                <label>{data.income}</label>
                            </div>
                        </div>

                    </div>
                </React.Fragment>
            ));
        const settings = {

            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <section id="successful-businessman" className="pt-5 pb-5 pseudo_border">
                <div className='text-center body-data'>
                    <div className='header-txt'>
                        <h1 style={styles.defaultTxtTH}>นักธุรกิจที่ประสบความสำเร็จ</h1>
                    </div>
                    <div className="container-padding">
                        <div className="text-center align-items-center justify-content-center">
                            <Slider {...settings}
                                nextArrow={<Arrow type="next" />}
                                prevArrow={<Arrow type="prev" />}
                            // appendDots={appendDots}
                            >
                                {renderSlides()}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
const styles = {
    bodyData: {
        background: '#ffebeb'
    },
    defaultTxtTH: {
        fontFamily: "Noto Sans Thai"
      }
}