import React, { Component } from 'react'
import './Hero.css'
import DownloadModal from './partial/modal/downloadModal';
import { withTranslation } from 'react-i18next';

class HeroContents extends Component {

    render() {
        const { t } = this.props
        return (
            //    <!-- ======= Hero Section ======= --> 
            <section id="hero" className='hero'>
                <div id='stars'></div>
                <div id='stars2'></div>
                <div id='stars3'></div>
                <div className="hero-container" data-aos="zoom-in" data-aos-delay="100">
                    <div className='col-sm-8'>
                        <h1>{t('hero_1.label')}</h1>
                    </div>
                    <div className='col-sm-8'>
                        <h2>{t('hero_2.label')}</h2>
                    </div>
                    <DownloadModal divHandleClass="col-sm-6 btn-box" btnHandleClass="btn btn-start" txt_lb_1={t('hero_3.label')} txt_lb_2={t('hero_1.label')}>
                        <p>Modal</p>
                    </DownloadModal>

                    <div className='col-sm-9 img-box'>
                        <img src="/assets/img/Heroartwork.png" alt="" />
                    </div>
                </div>
                <svg id="curveUpColor2" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
                    <path d="M0 100 C40 0 60 0 100 100 Z"></path>
                </svg>
            </section>
        )
    }
}
export default withTranslation()(HeroContents)
