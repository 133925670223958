import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

import "./CSS/partnerContent.css"
class partnerContent extends Component {
  render() {
    const { t } = this.props
    return (
      <section id="partner" className="pt-5 pb-5 d-flex pseudo_border">
        <div className="container">
          <div className="text-center align-items-center justify-content-center">

            <h1 >{t('partner_lb1.label')}</h1>
            <h2 >{t('partner_lb2.label')}</h2>

            <div className="partner-d-flex align-items-center justify-content-center">
              <div className='col-md-3 mx-auto'><img src="./assets/img/Partner/kerry.png" alt="" /></div>
              <div className='col-md-3 mx-auto'><img src="./assets/img/Partner/lianlianpay.png" alt="" /></div>
              <div className='col-md-3 mx-auto'><img src="./assets/img/Partner/huawei.png" alt="" /></div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default withTranslation()(partnerContent)
const styles = {


}