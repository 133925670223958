import React, { Component } from 'react'

import Abouts from './partial/aboutContent'
import Details from './partial/detailsContent'
import Download from './partial/downloadContent'
import LearnMore from './partial/learnMoreContent'
import Partner from './partial/partnerContent'
import BannerPartner from './partial/bannerPartnerContent'
import SuccessfulBusinessman from './partial/SuccessfulBusinessmanContent'


export default class MainContents extends Component {

  render() {
    
    return (
      <main id="main">
        <Abouts />
        <Details />
        <Download />
        <LearnMore />
        <Partner />
        <BannerPartner />
        <SuccessfulBusinessman />
      </main>
    )
  }
}
