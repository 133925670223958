import React, { Component } from 'react'
import './Header.css'
import { BiMenu } from "react-icons/bi";

import LanguageSelector from './LanguageSelector';
import { withTranslation } from 'react-i18next';

import classNames from 'classnames'

class Header extends Component {
    state = {
        flag: true
    }

    handleUpdate = () => {
        this.setState({ flag: !this.state.flag })
    }
    render() {
        const { t } = this.props
        var classes = classNames({
            'navbar': true,
            'navbar-mobile': this.state.flag === false
        })
        return (
            <header id="header" className="fixed-top d-flex align-items-center header-transparent">
                
                <div className="container d-flex align-items-center ">

                    <div className="logo">
                        <a href="/"><img src="assets/img/Logo.png" alt="" className="d-none d-lg-block img-fluid" /></a>
                    </div>
                    <nav id="navbar" className={classes}>
                        <ul>
                            <li><a className="nav-link scrollto active" id="linkHome" href="/">{t('home.label')}</a></li>
                            <li><a className="nav-link scrollto" href="/#about">{t('menu2.label')}</a></li>
                            <li><a className="nav-link scrollto" href="/#download">{t('menu3.label')}</a></li>
                            <li><a className="nav-link scrollto" href="/#learn-more">{t('menu4.label')}</a></li>
                            <li><a className="nav-link scrollto" href="/#banner-partner">{t('menu5.label')}</a></li>
                            <li><a className="nav-link scrollto" href="/#successful-businessman">{t('menu6.label')}</a></li>
                            <li><a className="nav-link scrollto" href="https://faq.droprich.co/">{t('menu8.label')}</a></li>
                            <li><a className="nav-link scrollto" href="/#contact">{t('menu7.label')}</a></li>
                            <div className="mobile-lang">
                                <li><LanguageSelector /></li>
                            </div>
                        </ul>
                        <BiMenu className="mobile-nav-toggle" onClick={this.handleUpdate}/>
                    </nav>
                    {/* <!-- .navbar --> */}
                    <div className="d-flex align-items-center mx-auto mobile-logo">
                        <a href="/"><img src="assets/img/Logo.png" alt="" className=" img-fluid" /></a>
                    </div>
                </div>
                <div className="d-none d-lg-block d-flex align-items-center">
                    <LanguageSelector />
                </div>

            </header>
            /* <!-- End Header --> */
        )
    }
}
export default withTranslation()(Header)