import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  var lang;
  switch (i18n.language) {
    case "th":
      lang = "US";
      break;
    case "en":
      lang = "TH";
  }
  const changeLanguage = (event) => {
    
    switch (i18n.language) {
      case "th":
        i18n.changeLanguage("en");
        document.body.style.fontFamily = "Noto Sans" ;
        break;
      case "en":
        i18n.changeLanguage("th");
        document.body.style.fontFamily = "Noto Sans Thai";
        break;
    }
  };

  return (
    <div className="nav-link " onClick={changeLanguage}>
      <ReactCountryFlag
        countryCode={lang}
        svg
        style={{
          //borderRadius : "100%",
          width: "30px",
          height: "30px",
          overflow: "hidden"
        }}
        title={lang}
      />
    </div>
  );
};

export default LanguageSelector;
