import React, { useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";

export default function VdoPlayer() {
    const [modal, setModal] = useState(false);
    const [videoLoading, setVideoLoading] = useState(true);

    const openModal = () => {
        setModal(!modal);
    };

    const spinner = () => {
        setVideoLoading(!videoLoading);
    };

    const video_id = "NCIDmRqdVeg"

    const uri = "https://www.youtube.com/embed/" + video_id + "?autoplay=1"

    return (
        <div className="">
            <button onClick={openModal} className="btn" >
                <img src="./assets/img/vdo-player1.png" alt="" />
                {modal ? (
                    <section className="modal__bg">
                        <div className="modal__align">
                            <div className="modal__content" >
                                <IoCloseOutline
                                    className="modal__close"
                                    arial-label="Close modal"
                                    onClick={setModal}
                                />
                                <div className="modal__video-align">
                                    {videoLoading ? (
                                        <div className="modal__spinner">
                                            <BiLoaderAlt className="modal__spinner-style" />
                                        </div>
                                    ) : null}
                                    <iframe
                                        className="modal__video-style"
                                        onLoad={spinner}
                                        loading="lazy"
                                        width="854"
                                        height="500"
                                        src={uri}
                                        title="YouTube embed"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                ) : null}

            </button>
        </div>
    );
}
