const businessman =[
    {
        name:"คุณ ฝ้าย",
        desc:"แม่บ้านเลี้ยงลูกอยู่บ้านคะ เคยขายออนไลน์มาบ้าง ขายตรงบ้าง แบบสต๊อกสินค้าบ้าง ลงทุนหลายหมื่น บางตัวขายหมดก็ดีไป แต่บางตัวขายไม่หมดก็ขาดทุนไป แต่ตั้งแต่มาเจอ Droprich  คือไม่ต้องลงทุน ไม่ต้องสต๊อกสินค้า บริษัทจัดส่งให้ทุกอย่าง ไม่มีความเสี่ยงสักอย่าง ไม่ต้องกลัวจะขายหมดไหม ไม่ต้องกลัวจะขาดทุน  แต่แค่สั่งสินค้ามาทดลองใช้กับตัวพอได้ผลลัพธ์ก็ แนะนำเพื่อนๆ คนรอบข้าง แค่นั้นง่ายมากๆ  ฝ้ายเริ่มสั่งซื้อสินค้าแค่ 3,600  ตอนนี้ได้คืนมา 61,490 บาท ก็รู้สึกดีใจที่ได้เจอกับ Droprich ดีใจที่เปิดโอกาสให้ตัวเองในวันนั้นคะ แล้วไม่ผิดหวังจริงๆคะ ขอบคุณบอสกุมภาที่สร้างแอพพลิเคชันดีดีที่สามารถสร้างรายได้แม้จะอยู่บ้านขอแค่ทีมือถือเครื่องเดียวก็สร้างรายได้ๆตลอดคะ",
        income:"61,490 Bath",
        img:"IMG_0288.JPG"
    },
    {
        name:"คุณ ต่าย",
        desc:"สวัสดีครับ ชื่อต่ายนะครับ เคยขายออนไลน์มาก่อน ที่ตัดสินใจทำธุรกิจดรอปริชเพราะไม่ต้องลงทุนสต๊อคสินค้า แถมยังสามารถทำกำไรสูงถึง 75% ซึ่งไม่มีความเสี่ยงเลย ทำมา 1 ปี รายได้รวม 2.4 ล้าน แถมยังได้รถยนต์ ปอเช่ taycan 4 s 1 คัน ขอบคุณดรอปริช รหัสแนะนำ : rPM4dz",
        income:"2.4 million",
        img:"IMG_6061.JPG"
    },
    {
        name:"คุณ นก นิธิกานต์ ปรีดา",
        desc:"นก นิธิกานต์ ปรีดา ก่อนหน้านี้ เป็นแม่ค้า ขายเกี๊ยวซ่า ตัดสินใจทำดรอปริช เพราะเห็นโอกาสที่จะประสบความสำเร็จได้ ตอนนี้ทำมา 10 เดือน รับรายได้มากกว่า 1 ล้านบาท ทำให้ชีวิตดีขึ้น ดูแลครอบครัว และลูกได้ แบบไม่ต้องลำบาก รหัสแนะนำ UZpdL5",
        income:"more than 1 million",
        img:"nokdroprich.jpg"
    },
    {
        name:"คุณ นิว",
        desc:"สวัสดีค่ะ ชื่อนิว นะคะ เรียนจบ ป.ตรี ตรงกับช่วงพิษโควิดระบาด เราทั้งคู่ไม่เคยทำงานประจำ ตั้งใจค้นหางานที่ใช่ จนไปเจอธุรกิจขายตรงที่หนึ่ง แต่ต้องใช้เงินลงทุนรวมเกือบหลักแสน ก็เลยต้องกู้หนี้ยืมสินมาลงทุน ทำให้มีหนี้ตั้งแต่เรียนจบ และก็ได้ของมากองอยู่บ้าน สุดท้ายเราก็ได้ไปทำงานฟรีแลนด์(ทั้งประจำและพาร์ทไทม์) เพื่อจะมาใช้หนี้จนหมดแล้วจนได้มาเจอบอสกุมภา และได้ตัดสินใจลงมือทำ Droprich แบบจริงจังภายในระยะเวลาเพียงแค่ 6-7 เดือน ทำให้นิวกับรัน มีรายได้รวมกว่า 750,000 บาท มีโอกาสได้ซื้อ iPhone 13 Pro และ iPhone 13 Pro Max และ ได้มีโอกาส ซื้อทองใส่อีก 2 บาท ยังทำให้ชีวิตของเราทั้งคู่ดีขึ้น การใช้ชีวิตดีขึ้น การกิน และทุกสิ่งทุกอย่างดีขึ้นกว่าเมื่อก่อนมาก ตั้งแต่ได้เข้ามาทำธุรกิจ Droprich รหัสแนะนำ : Seaqab",
        income:"0.75 million",
        img:"IMG_7315.jpg"
    },
    {
        name:"คุณ แบงค์",
        desc:"สวัสดีครับ ชื่อแบงค์ อายุ 26 ปี แบงค์ทำงานประจำมา 3 ปี ขับฟู้ดแพนด้าอีก 1 ปี เป็นคนที่ตามหางานออนไลน์มาตลอด เพราะไม่ชอบตื่นเช้าไปทำงานเหมือนเดิมทุกวัน  แต่ก็ไม่เคยเจองานที่ใช้ เพราะลงทุนเยอะ และไม่มีใครสอนงานจนได้มาเจอดรอปริช ก็ประทับใจเลย เพราะมีระบบซัพพอตให้ทุกอย่าง ไม่ต้องลงทุนสต๊อกสินค้า และมีระบบสอนงานให้ทั้งคนเก่าคนใหม่ ตัดสินใจตั้งแต่วันแรกที่ได้ฟังงานทั้งๆที่ไม่มีตัง ก็หาตังมาทำจนได้ตอนนี้ได้ชีวิตใหม่เพราะดรอปริช และเป็นสิ่งที่ฝันมาตลอด คือ ไม่ต้องไปทำงานประจำ ตอนนี้ไปได้ 6 แสนกว่าบาท #ขอบคุณดรอปริช รหัสแนะนำ : 26MQy8",
        income:"0.6 million",
        img:"276952089_1366947523748591_3987973118156097766_n.jpg"
    },
    {
        name:"คุณ บัง (แลมโบ)",
        desc:"อาชีพ เคยขับวินมอไซร์ ขับแกร๊ป ขับแพนด้าก่อนทำดรอปริชไม่มีอะไรเลยเพราะ‼️ตกงาน6เดือนผลลัพธ์ คือ ทำดรอปริชมา6-7เดือน ได้ iPhone 13 Pro Max ได้รถยนต์ฮอนด้าซีวิคตัวใหม่ล่าสุด รายได้รวม 790,000 ฿รหัสแนะนำ l0WH62 (แอลเล็ก-เลขศูนย์-ดับบิวใหญ่-เฮ็ดใหญ่-เลขหก-เลขสอง)",
        income:"0.79 million",
        img:"IMG_2206.PNG"
    },
    {
        name:"คุณ ประกอบ มิ่งคำ",
        desc:"อาชีพ : พนักงานโรงงาน อุตสาหกรรมอมตนคร ชลบุรี (7ปี) \n ก่อนมาทำดรอปริช :โรงงานหยุดกิจการชั่วคราว 3 เดือนเพราะได้รับผลกระทบจากโควิด \n ผลลัพธ์ : ทำดรอปริชแล้วมีรายได้รวม 1,030,000 บาท ความประทับใจ : มองเห็นวิสัยทัศน์ของผู้บริหารและบริษัท ระบบการทำงานที่ช่วยเหลือคนใหม่ ระบบการแบ่งปัน ช่วยเหลือซึ่งกันและกัน และความรู้ด้านต่างๆที่บริษัทมอบให้ รหัสแนะนำ  0BzGkq (เลขศูนย์-บีใหญ่-แซตเล็ก-จีใหญ่-เคเล็ก-คิวเล็ก)",
        income:"1 million",
        img:"2..jpg"
    },
    {
        name:"ผอ.รฐา วระกาญจน์",
        desc:"ปัจจุบันรับราชการ ดำรงตำแหน่งผู้อำนวยการโรงเรียนจัดเวลาทำธุรกิจดรอปริช 3 ชั่วโมงต่อวัน 5 เดือนผลลัพธ์ 696,000 บาทรหัสแนะนำ j1eiup5sXc",
        income:"0.69 million",
        img:"IMG_0276.JPG"
    },
    {
        name:"คุณ สุกสัน",
        desc:"ชื่อ สุกสัน อายุ38 ปี ทำ ธุรกิจส่วนตัว เเละเปันเเม่ค้าออนไลน์ สายสต๊อกเปีดบิลสินค้าเป็นดีเลอร์รายไหญ่ที่ลาว วันที่มาเจอดอับลิดดีใจมากค่ะ  ไม่คิดว่าจะมีแอับที่เป็นเคื่องมือผลิดเงีนในโลกออนไลน์ได้ง่ายก่อนที่เคยทำเเละ ปัจจุบัน ทำดอับลิด 7 เดือน ได้กำไลจากการ โปรโมทแอป อยู่ 464,520฿ ขอบคุณค่ะ ผู้ก่อตั้ง คือ บอสกุมภาพันธ์ ทำให้ชีวิดเปี่ยนไปในทางทีดีขื้อมากเลยค่ะ รหัส แนะนำ63QEXtz4k2",
        income:"0.46 million",
        img:"IMG_0266.JPG"
    },
    {
        name:"คุณ อ๋อย",
        desc:"สวัสดีค่ะ ชื่ออ๋อยนะคะ จบปริญญาโท การกำกับดูแลกิจการ จุฬาลงกรณ์มหาวิทยาลัย ทำงานประจำมา 20 ปี เงินเดือนไม่พอใช้ ไม่ทีเงินเก็บ เลยมาทำออนไลน์ควบคู่งานประจำ เคยจมทุนจากระบบสต๊อกหลักแสน ปัจจุบันออกจากงานประจำมาทำออนไลน์ 100% และทำดรอปริช 7 เดือน มีรายได้ 308,000 บาท โดยไม่ต้องลงทุนสต๊อกของเลยหมดความกังวลเรื่องจมทุนค่ะ 👉 รหัสแนะนำ fKCQsg",
        income:"0.3 million",
        img:"IMG_3632.PNG"
    },
    {
        name:"คุณ ณัฏฐรินทร์ สุวรรณวงศ์ (อีฟ)",
        desc:"อาชีพ : พนักงานประจำ วิทยากรบริษัทประกันชีวิตความประทับใจ : อีฟทำดรอปริช ควบคู่กับการทำงานประจำ สามารถสร้างรายได้ มากกว่า 2 แสนบาท แบบไม่กระทบงานประจำเลย ดรอปริช เป็นระบบที่ตอบโจทย์ชีวิต มากๆ ไร้ความเสี่ยง ไม่ต้องกังวลเรื่องเงินลงทุน แค่ช้อปปิ้ง ก็สามารถมีเครื่องมือสร้างรายได้ง่ายๆ #ระบบดีมีอยู่จริง รหัสแนะนำ : V6zlra",
        income:"0.2 million",
        img:"IMG_20220318_215559.jpg"
    },
    {
        name:"คุณ สไปร์ท",
        desc:"เวลาในการทำงานประจำมา 9 ปี เงินเดือน 9 พัน ถึง 1 หมื่นบาท ใช้เดือนชนเดือนบางเดือนไม่พอใช้เป็นอารมณ์ที่น่าเบื่อกับการใช้ชีวิตมากจนมาเจอดรอปริช  ดรอปริชได้มอบชีวิตใหม่ให้กับสไปร์ท ได้มีชีวิตที่อิสระไม่ต้องตื่นตอนเช้าไปทำงาน ไม่ต้องทนบังคับให้ทำนู้นนี่นั้น และไม่ต้องมานั่งบ่นกับตัวเองว่าทุกวันนี้เราจะทำไปเพื่ออะไร ต้องขอบคุณบอสกุมภาที่ก่อตั้งบริษัทดรอปริชขึ้นมา รักดรอปริชที่สุด🙏 รหัสแนะนำVhQ6Nr",
        income:"0.5 million",
        img:"IMG_7399.JPG"
    },
    {
        name:"คุณ เมย์",
        desc:"สวัสดีค่ะ ชื่อ หญิงเมย์นะคะ เรียนจบปริญญาตรีคณะวิทยาศาสตร์ มหาวิทยาลัยธรรมศาสตร์ ไม่เคยทำงานประจำ เนื่องจากพ่อเสียตั้งแต่ตอนเรียนปี3 เลยหางานพาทไทม์ทำ สุดท้ายไปเจอเป็น ขายตรง โดนโกงหลักแสน เปลี่ยนไปขายของออนไลน์ทั้งแบบสต๊อก และไม่สต๊อก ลงทุนหลักพัน ถึงหลักล้านเจ๊งมาเยอะ ทุนหายกำไรหด ทำมา30กว่าแบรนด์ ถึงจุดนึง ทุนจมกว่า ครึ่งล้านบาท จนมาเจอดรอปริช ที่มีระบบซัพพอร์ตทุกอย่าง แบบไม่ต้องจมทุน ไม่ต้องสต๊อกสินค้า ไร้ความเสี่ยง100% ทำให้ทำงานง่ายขึ้น ขอบคุณระบบดีๆที่สามารถทำงานที่ไหนก็ได้ แต่สามารถมีรายได้ตลอด24ชม. ทำให้เมย์มีรายได้กว่า 950,000฿ ในระยะเวลาเพียงแค่7เดือน ที่จริงจัง รหัสแนะนำ qWcKnw",
        income:"0.95 million",
        img:"IMG_4289.JPG"
    },
    {
        name:"คุณ รัน",
        desc:"สวัสดีครับ ชื่อรัน นะครับ เรียนจบ ป.ตรี ตรงกับช่วงพิษโควิดระบาด เราทั้งคู่ไม่เคยทำงานประจำ ตั้งใจค้นหางานที่ใช่ จนไปเจอธุรกิจขายตรงที่หนึ่ง แต่ต้องใช้เงินลงทุนรวมเกือบหลักแสน ก็เลยต้องกู้หนี้ยืมสินมาลงทุน ทำให้มีหนี้ตั้งแต่เรียนจบ และก็ได้ของมากองอยู่บ้าน สุดท้ายเราก็ได้ไปทำงานฟรีแลนด์(ทั้งประจำและพาร์ทไทม์) เพื่อจะมาใช้หนี้จนหมดแล้วจนได้มาเจอบอสกุมภา และได้ตัดสินใจลงมือทำ Droprich แบบจริงจังภายในระยะเวลาเพียงแค่ 6-7 เดือน ทำให้นิวกับรัน มีรายได้รวมกว่า 750,000 บาท มีโอกาสได้ซื้อ iPhone 13 Pro และ iPhone 13 Pro Max และ ได้มีโอกาส ซื้อทองใส่อีก 2 บาท ยังทำให้ชีวิตของเราทั้งคู่ดีขึ้น การใช้ชีวิตดีขึ้น การกิน และทุกสิ่งทุกอย่างดีขึ้นกว่าเมื่อก่อนมาก ตั้งแต่ได้เข้ามาทำธุรกิจ Droprich รหัสแนะนำ : Seaqab",
        income:"0.75 million",
        img:"IMG_7283.JPG"
    },
]
export default businessman; 