import React, { Component, useState } from 'react'
import { withTranslation } from 'react-i18next';
import './CSS/aboutContent.css'
import VdoPlayer from './modal/VdoPlayer'

class aboutContent extends Component {

  render() {
    
    const { t, i18n } = this.props
    var h2_txt = "";

    switch (i18n.language) {
      case "th":
        var droprich = <span style={styles.defaultTxtEN}>DROPRICH</span>
        h2_txt = <h1 id="txt_h2">มาดูกัน {droprich} คืออะไร</h1>;
        break;
      case "en":
        h2_txt = <h1 id="txt_h2">{t('about_lb_h2.label')}</h1>;
    }

    return (
      <section id="about" className="about pb-5 d-flex our-work-process">

        <div className="about-main">
          <div className="container " >
            <div className="row text-center justify-content-center" style={styles.icon}>
              <div className="col-12">
                <h1 >{t('about_lb_h1.label')}</h1>
              </div>
              <div className="col-sm-2 step-box pb-5 ">
                <div className="step-box-con ">
                  <div className="step-icon"><img src="./assets/img/AboutIcon/WebIcon1.png" alt="" /></div>
                  <label >{t('about_lb_icon1.label')}</label>
                </div>
              </div>

              <div className="col-sm-2 step-box pb-5">
                <div className="step-box-con ">
                  <div className="step-icon"><img src="./assets/img/AboutIcon/WebIcon2.png" alt="" /></div>
                  <label >{t('about_lb_icon2.label')}</label>
                </div>
              </div>
              <div className="col-sm-2 step-box pb-5">
                <div className="step-box-con">
                  <div className="step-icon"><img src="./assets/img/AboutIcon/WebIcon3.png" alt="" /></div>
                  <label >{t('about_lb_icon3.label')}</label>
                </div>
              </div>
              <div className="col-sm-2 step-box pb-5">
                <div className="step-box-con">
                  <div className="step-icon"><img src="./assets/img/AboutIcon/WebIcon4.png" alt="" /></div>
                  <label >{t('about_lb_icon4.label')}</label>
                </div>
              </div>
              <div className="col-sm-2 step-box pb-5">
                <div className="step-box-con">
                  <div className="step-icon"><img src="./assets/img/AboutIcon/WebIcon5.png" alt="" /></div>
                  <label >{t('about_lb_icon5.label')}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center justify-content-center VdoPlayer" >
            <div className="col-12">
              {h2_txt}
            </div>
            <div className="col-sm-9 mx-auto">
              <VdoPlayer />
            </div>

          </div>
        </div>

        <svg id="curveUpColor3" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
          <path d="M0 100 C40 0 60 0 100 100 Z"></path>
        </svg>
      </section>

    )
  }
}
export default withTranslation()(aboutContent)
const styles = {
  defaultTxtEN: {
    fontFamily: "Noto Sans"
  },

}


