import React, { Component } from 'react'
import './Footer.css'
import { withTranslation } from 'react-i18next';
import DownloadModal from '../Contents/partial/modal/downloadModal';

class Footer extends Component {
    render() {
        const { t } = this.props
        return (

            <footer id="contact" className="footer">
                <div className="footer-top">

                    <div className="container ">
                        <div className="row ">
                            <div className="col-btn ">
                                <DownloadModal divHandleClass="start-btn-footer" btnHandleClass="btn " txt_lb_1={t('hero_3.label')} txt_lb_2={t('hero_1.label')}>
                                    <p>Modal</p>
                                </DownloadModal>
                            </div>
                            <div className="col-content ">
                                <div className="row">
                                    <div className="header-logo-text col-lg-7">
                                        {/* <img src="./assets/img/logo.png" alt="" /> */}
                                        <img src="./assets/img/logo-white.png" alt=""/>
                                        <h1>{t('hero_1.label')}</h1>
                                        <div className="header-logo-text-detail col-sm-9">
                                            <p>{t('hero_2.label')}</p>
                                          
                                        </div>
                                        <div className="copyright" style={styles.defaultTxtEN}>
                                            &copy; Droprich Co., Ltd. All Rights Reserved
                                        </div>
                                    </div>

                                    <div className="col-lg-3 footer-links">
                                        <h4>{t('footer_lb_company.label')}</h4>
                                        <ul>
                                            {/* <li> <a href="/service-policy" style={styles.defaultTxtEN}>SERVICE POLICY</a></li> */}
                                            <li> <a href="/privacy-policy" style={styles.defaultTxtEN}>PRIVACY POLICY</a></li>
                                            <li> <a href="/terms-and-conditions" style={styles.defaultTxtEN}>TERMS AND CONDITIONS</a></li>
                                            {/* <li><i className="bi bi-chevron-right"></i> <a href="#" style={styles.defaultTxtEN}>PRIVACY POLICY (ENG)</a></li> */}
                                            <li> <a href="/purchase-policy" style={styles.defaultTxtEN}>PURCHASE POLICY</a></li>
                                        </ul>
                                    </div>

                                    <div className="col-lg-2 footer-links">
                                        <h4>{t('contact_us.label')}</h4>
                                        <ul>
                                            <li> <a href="/company-address">{t('footer_lb_company_address.label')}</a></li>
                                            {/* <li> <a href="#">{t('footer_lb_warehouse_address.label')}</a></li> */}

                                        </ul>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row store-icon">
                        <div className='store-icon-box '>
                            <a href={process.env.REACT_APP_GPLAY} target="_blank" rel="external"><img src="./assets/img/StoreIcon/Play_Store_black.png" alt="" /></a>
                        </div >
                        <div className='store-icon-box'>
                            <a href={process.env.REACT_APP_ASTORE} target="_blank" rel="external"><img src="./assets/img/StoreIcon/App_Store_black.png" alt="" /></a>
                        </div>
                        <div className='store-icon-box'>
                            <a href={process.env.REACT_APP_AGALLERY} target="_blank" rel="external"><img src="./assets/img/StoreIcon/Appgallery_black.png" alt="" /></a>
                        </div>
                    </div>
                </div>

            </footer>

        )
    }
}
export default withTranslation()(Footer)

const styles = {
    defaultTxtEN: {
        fontFamily: "Noto Sans"
    },
    // logoSize:{
    //     width:'119px',
    //     height:'119px',
    // }
}