import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import "./CSS/detailsContent.css"

class detailsContent extends Component {
  render() {
    const { t } = this.props
    return (
      <section id="details-content" className="pt-5 pb-5 d-flex pseudo_border">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-5   mt-4 mt-md-0 order-md-1 order-2">
              <img alt="image" className="img-fluid" src="./assets/img/ContantArtwork/Product.png" />
            </div>
            <div className="col-12 col-md-6 order-1 order-md-2">
              <h1 >{t('detail_1_1.label')}</h1>
              <h2 >{t('detail_1_2.label')}</h2>
              <p className="text-h3 mt-3" >{t('detail_1_3.label')}</p>
            </div>
          </div>
          <div className="row align-items-center justify-content-center pt-5 pb-5">
            <div className="col-12 col-md-6 offset-md-1  ">
              <h1 >{t('detail_2_1.label')}</h1>
              <h2 >{t('detail_2_2.label')}</h2>
              <p className="text-h3 mt-3" >{t('detail_2_3.label')}</p>
            </div>
            <div className="col-12 col-md-5   mt-4 mt-md-0">
              <img alt="image" className="img-fluid" src="./assets/img/ContantArtwork/Express.png" />
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-5   mt-4 mt-md-0 order-md-1 order-2">
              <img alt="image" className="img-fluid" src="./assets/img/ContantArtwork/Money.png" />
            </div>
            <div className="col-12 col-md-6 order-1 order-md-2">
              <h1 >{t('detail_3_1.label')}</h1>
              <h2 >{t('detail_3_2.label')}</h2>
              <p className="text-h3 mt-3" >{t('detail_3_3.label')}</p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default withTranslation()(detailsContent)
const styles = {
  
}