import React, { Component,Suspense } from 'react'
import './App.css';

import Header from './Header/Header';
import MainContents from './Contents/MainContents';

import HeroContents from './Contents/HeroContents';
import Footer from './Footer/Footer';

import { IoArrowUpOutline } from "react-icons/io5";

export default class App extends Component {
  
    componentDidMount() {
    const script = document.createElement("script");
    script.src = "/assets/static/libs/function_script.js";
    script.async = true;
    // script.onload = () => this.scriptLoaded();
    document.body.appendChild(script);
  }
  
  render() {
    return (
      <Suspense fallback={null}>
        <Header />
         <HeroContents />
         <MainContents />
         <Footer />
         <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><IoArrowUpOutline /></a>
       </Suspense>
    )
  }
}