import * as React from 'react';
// import ReactMarkdown from 'markdown-to-jsx';
import ReactMarkdown from 'react-markdown';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';

// const options = {
//     overrides: {
//         h1: {
//             component: Typography,
//             props: {
//                 gutterBottom: true,
//                 variant: 'h4',
//                 color: "#D63864"
//             },
//         },
//         h2: {
//             component: Typography,
//             props: { gutterBottom: true, variant: 'h6', },
//         },
//         h3: {
//             component: Typography,
//             props: { gutterBottom: true, variant: 'subtitle1', color: "#D63864" },
//         },
//         h4: {
//             component: Typography,
//             props: {
//                 gutterBottom: true,
//                 variant: 'caption',
//                 paragraph: true,
//             },
//         },
//         p: {
//             component: Typography,
//             props: { paragraph: true, fontWeight: 700 },
//         },
//         a: { component: Link },
//         li: {
//             component: (props) => (
//                 <Box component="li" sx={{ mt: 1 }}>
//                     <Typography component="span" {...props} />
//                 </Box>
//             ),
//         },
//         blockquote: {
//             component: (props) => (
//                 <blockquote {...props} />
//             )
//         }
//     },
// };

const components = {
    h1: ({ ...props }) => (
        // <Typography variant="h3" sx={{ color: "#D63864", gutterBottom: true, }} {...props} />
        <h1 { ...props } className='text-center'/>
    ),
    h2: ({ ...props }) => (
        // <Typography variant="h6" {...props} />
        <h5 { ...props }/>
    ),
    h3: ({ ...props }) => (
        // <Typography variant="subtitle1" sx={{ gutterBottom: true, color: "#D63864" }}  {...props} />
        <h5 { ...props } className='text-center'/>
    ),
    h4: ({ ...props }) => (
        // <Typography variant="caption" sx={{ gutterBottom: true, paragraph: true, }}  {...props} />
        <h5 { ...props }/>
    ),
    h5: ({ ...props }) => (
        // <Typography variant="caption" sx={{ gutterBottom: true, paragraph: true, }}  {...props} />
        <p { ...props }/>
    ),
    p: ({ ...props }) => (
        // <Typography variant="body1" sx={{ paragraph: true, fontWeight: 700 }}  {...props} />
        <p {...props}/>
    ),
    // a: ({ ...props }) => (
    //     <a target="_blank" {...props} />
    // ),
    blockquote: ({ ...props }) => (
        <blockquote style={{
            color: "#666",
            margin: '0',
            paddingLeft: '3em',
            borderLeft: '0.5em #eee solid',
            // fontWeight: 400
        }} {...props} />
    ),
}
export default function Markdown(props) {
    // return <ReactMarkdown options={options} {...props} />;
    return <ReactMarkdown components={components} {...props} />;
}