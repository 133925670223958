import React, { Component, Suspense } from 'react'

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { withTranslation } from 'react-i18next';

class PurchasePolicy extends Component {
  render() {
    const { t } = this.props
    return (
      <Suspense fallback={null}>
        <Header />
        <main id="main">
          <section id="purchase-policy" className="pt-5 pb-5">
            <div className="container" >
              <h1 className='text-center'>{t('purchase_policy_h.label')}</h1>

              <p>{t('purchase_policy_p1.label')}</p>
              <p>{t('purchase_policy_p2.label')}</p>
              <h5>{t('purchase_policy_p3.label')}</h5>
              <p>{t('purchase_policy_p4.label')}</p>
              <h5>{t('purchase_policy_p5.label')}</h5>
              <p>{t('purchase_policy_p6.label')}</p>
              <p>{t('purchase_policy_p7.label')}</p>
              <p>{t('purchase_policy_p8.label')}</p>
              <h5>{t('purchase_policy_p9.label')}</h5>
              <p>{t('purchase_policy_p10.label')}</p>
              
              <h5>{t('policy_p1.label')}</h5>
              <p>{t('policy_p2.label')}</p>
              <ul>
                <li>{t('policy_p3.label')}</li>
                <li>{t('policy_p4.label')}</li>
                <li>{t('policy_p5.label')}</li>
                <li>{t('policy_p6.label')}</li>
                <li>{t('policy_p7.label')}</li>
              </ul>
              <h5>{t('policy_p8.label')}</h5>
              <ul>
                <li>{t('policy_p9.label')}</li>
                <li>{t('policy_p10.label')}</li>
                <li>{t('policy_p11.label')}</li>
                <li>{t('policy_p12.label')}</li>
              </ul>
              <h5>{t('policy_p13.label')}</h5>
              <ul>
                <li>{t('policy_p14.label')}</li>
                <li>{t('policy_p15.label')}</li>
                <li>{t('policy_p16.label')} <br />{t('policy_p17.label')}</li>
              </ul>
              <h5>{t('policy_p18.label')}</h5>
              <ol>
                <li>{t('policy_p19.label')}</li>
                <li>{t('policy_p20.label')}</li>
                <li>{t('policy_p21.label')}</li>
                <li>{t('policy_p22.label')}</li>
              </ol>
              <h5>{t('policy_p23.label')}</h5>
              <p>{t('policy_p24.label')}</p>
              <p>{t('policy_p25.label')}</p>
            </div>
          </section>
        </main>
        <Footer />
      </Suspense>
    )
  }
}
export default withTranslation()(PurchasePolicy)