import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import "./CSS/learnMoreContent.css"

class learnMoreContent extends Component {
  
  render() {
    const handleClick = () => {
      window.open("https://www.youtube.com/c/DroprichOfficialApplication");
    };
    const { t } = this.props
    return (
      <section id="learn-more" className="learn-more pt-5 pb-5 d-flex pseudo_border">
        <div className="container">
          <div className="text-center align-items-center justify-content-center">

            <h1 >{t('learn_more_lb1.label')}</h1>

            <h2 >{t('learn_more_lb2.label')}</h2>

            <button type="button" className="btn"  onClick={handleClick}>{t('learn_more_lb3.label')}</button>

          </div>
        </div>
      </section>
    )
  }
}
export default withTranslation()(learnMoreContent)
const styles = {

}